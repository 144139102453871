
export let previewFiles = [];
export function upload(selector, options = {}) {

    const onUpload = options.onUpload ?? noop;
    const input = document.querySelector(selector);   
    
    const preview = element('div', 'preview');   
    
    const openBtn = element('button', 'btn', 'openBtn');
    openBtn.textContent = 'Open files';
    const uploadBtn = element('button', 'btn', 'uploadBtn');
    uploadBtn.textContent = 'Upload';
    

    input.insertAdjacentElement('afterend', preview)
    input.insertAdjacentElement('afterend', uploadBtn);
    input.insertAdjacentElement('afterend', openBtn);
    const triggerInput = () => input.click();

    // handle params
    if (options.multi) {
        input.setAttribute('multiple', true);
    }

    if (Array.isArray( options?.accept )) {
        input.setAttribute('accept', options.accept.join(','));
    }

    

    
    const changeHandler = event => {

        
        if (event.target.files.length === 0) {
            return
        } 

        //setting UI
        openBtn.textContent = 'add files';
        preview.style.display = 'flex';
        uploadBtn.style.display = 'inline-block';

        const files = Array.from(event.target.files);

        
        //managing every file we got
        nextFile: for (let i=0; i<files.length; i++) {
            let file = files[i];
            
            
            if (!file.type.match('text')) {
                continue nextFile;
            }

            
            let currentFile = createInfo( file);
            currentFile.blob = file;
            let stockLength = previewFiles.length;
            
            for (let k = 0; k < stockLength ; k++) {
                 
                if (previewFiles[k].name === currentFile.name) {
                    continue nextFile;
                } 
            }

            
            
       
        
            const reader = new FileReader();

            // Event listener for assync
            reader.onload = ev => {
                let innerText = ev.target.result;
                
                //some preview for file
                let text = innerText.replaceAll('\n', '<br/>');
         
                const fileCard = element("div", "fileCard");
                
                const previewFile = element("div", 'previewFile');

                preview.insertAdjacentElement('beforeend', fileCard);
                let open = element('div', 'open');
                
                fileCard.insertAdjacentElement('afterbegin', open)
                fileCard.insertAdjacentHTML('afterbegin', `
                
                <div class="removeFileCard" data-size="${file.size}" data-name="${file.name}">
                    &times;
                </div>
                <div class="fileInfo">  ${formatBytes(file.size)}</div>
                `);
                
                fileCard.insertAdjacentElement('afterbegin', previewFile);
                fileCard.insertAdjacentHTML('afterbegin', `<h3>${file.name}</h3>`);

                previewFile.insertAdjacentHTML('afterbegin', `<p>${text}</p>`);

                const uploadIndicator = element('div', 'uploadIndicator');
                previewFile.insertAdjacentElement('afterend', uploadIndicator);

                
                currentFile.text = innerText;
                currentFile.open = open;
                currentFile.card = fileCard;
                currentFile.uploaded = false;
                currentFile.indicator = uploadIndicator;
                
                previewFiles.push(currentFile);
            }
             
             
            
            
            reader.readAsText(file);
        }
    }

    const removeHandler = event => {
        let {name: clickedName} = event.target.dataset;
        
        if(!clickedName) {
            return;
        }

        
        previewFiles = previewFiles.filter (file => (file.name !== clickedName) );
        
        
        const fileCard = event.target.offsetParent;
        fileCard.classList.add('removing');

        setTimeout(() => {
            if (!previewFiles.length) {
                preview.style.display = 'none';
                openBtn.textContent = 'Open files';
                uploadBtn.style.display = 'none';
            }
            
            fileCard.remove();
        }, 300);
    }

    const uploadHandler = () => {
        //Хочу дизейблить добавление файлов

        preview.querySelectorAll('.removeFileCard')
            .forEach(element => element.remove());
        
        
        
        onUpload(previewFiles);
        
    }
    //Redirect and handle
    openBtn.addEventListener('click', triggerInput);
    input.addEventListener('change', changeHandler);
    preview.addEventListener('click', removeHandler);
    uploadBtn.addEventListener('click', uploadHandler)
}









function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

function element(tag, ...classes) {
    let element = document.createElement(tag);
    if (classes.length) {
        element.classList.add(...classes)
        
    }
    return element;
}
class FileInfo {
    constructor (name, lastModified, size){
        this.name = name;
        this.lastModified = lastModified;
        this.size = size;
    }
    hasSameInfo (obj) {
        return ((obj.lastModified === this.lastModified) && (obj.name === this.name) && (obj.size === this.size));
    }
}
function createInfo(obj) {

    let {lastModified, name, size} = obj;
    let fileInfo = new FileInfo(  name, lastModified, size);
    return fileInfo;
}

function noop() {}

