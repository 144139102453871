export function objFromCSS (innerText) {
    let cssText = innerText;
    //уберем зону с @media

const mediaStarts = cssText.indexOf('@media');
cssText = cssText.slice(0, mediaStarts);



//Находит все индексы symbol в text
function findAllSymbols  (text, symbol) {
const symbolPositions =[];

let pos = 0;
while (true) {

  let foundPos = text.indexOf(symbol, pos);
  if (foundPos == -1) break;

  symbolPositions.push(foundPos);
  pos = foundPos + 1; 
}

return symbolPositions;
}



//Возвращает массив с кусками text между sym1 и sym2

let getTextBetween = (text, sym1, sym2) =>{

    //найдем позиции символов sym1 и sym2
    const startPosition = findAllSymbols(text, sym1);
    const endPosition = findAllSymbols(text,sym2);
    
    
   
    
    
    //Вычленим куски
    let textParts =[];
    startPosition.forEach((element, index) =>{
        textParts.push(text.slice(startPosition[index]+2, endPosition[index]));
    });
    
    return textParts;
}
const breketOpenPositions = findAllSymbols(cssText, '{');

let propertiesAsString = getTextBetween(cssText, "{","}")



/*Возвращает позицию символа symbol проходясь в обратном направлении по 
text начиная с символа init*/
let findSymbPositionFromEnd = (symbol, text, init=text.lenght) =>{
    init=+init;
    let currentSymbol;
    let k=0;

    while (currentSymbol!=symbol) {
        currentSymbol=text[init-k];
        k++;
    }
    return (init-k+2);
}

//Найдем индексы с которых начинается селектор
let text='\n'+cssText;

let selectorStartPositions=[];

for (let i=0; i<breketOpenPositions.length;i++) {
    selectorStartPositions.push(findSymbPositionFromEnd('\n', text, breketOpenPositions[i]));
}

//Вычленим селекторы
let selectors =[];
selectorStartPositions.forEach((element, index) =>{
    selectors.push(text.slice(selectorStartPositions[index], breketOpenPositions[index]+1).trim()); 
    //+1 во втором параметре потому что обрезался последний символ в случае отсутсвия пробела .selector{ 
});







//создадим объект с объектами из селекторов и свойств как строк



let css = {}


//Заранее создадим класс селекторов чтобы методы туда пихать и они не были в ключах
class Selector {
  propAsStr( prop) {
    return `${prop}: ${this[prop]};`
  } 
}


// создадим ключи-объекты селекторы, запихнем туда свойства и уберем коментарии
for (let i=0; i<selectors.length; i++) {
    let comments = getTextBetween(propertiesAsString[i], '/*', '*/');
    comments.forEach(comment => {
      propertiesAsString[i]=propertiesAsString[i].replace(`/*${comment}*/`,'')
  
    });

    css[selectors[i]]=new Selector;
    css[selectors[i]].properties = propertiesAsString[i];
    
    if (comments.length>0) {
        css[selectors[i]].comments = `/*${comments}*/`;
    }
}







//Сделаем из свойств в виде строки вложенные объекты 




Object.values(css).forEach((selector) => {
    
  selector.properties='\n'+selector.properties;

  
  let propertiesInString = selector.properties;
  let propertiStartsAll = findAllSymbols(propertiesInString, '\n');

  let properties =[];
  
  for (let i=0; i<propertiStartsAll.length; i++) {
      
      
      let currentProperty=propertiesInString.slice(propertiStartsAll[i]+1, propertiStartsAll[i+1]).trim();
      if (currentProperty) {
          properties.push(currentProperty);
      }
  }
  
  properties.forEach (str => {
      let key = str.slice(0, str.indexOf(':'));
      let value = str.slice(str.indexOf(':')+1, -1).trim();
      selector[key]=value;

  });


});

css.keys = function () {
    return Object.keys(this);
};

//Добавим метод, который возвращает набор селекторов-ключей по свойству
css.getKeysWith = function (propertyLF) {
    let keysWithThis =[];
    this.keys().forEach(selector => {
        if (this[selector].hasOwnProperty(propertyLF)) {
            keysWithThis.push(selector);
        };
    });
    
    return keysWithThis;
};

//same as above but for html usage
css.getAsListForHTML = function (propertyLF) {
    let text = ``;
    
    this.keys().forEach(selector => {
        if (this[selector].hasOwnProperty(propertyLF)) {
          text += `<p> ${selector} </p>`;
        };
    });
    
    return text;
};
  
  
  
  
//добавим метод возвращающий стили только с искомым свойством (игнорируются остальные свойства)
css.getStylesWith = function (prop) {
    let stylesWith = ``;
    this.getKeysWith(prop).forEach((key) => {
      stylesWith += `${key} {\n ${this[key].propAsStr(prop)}\n}\n`;
    });
    return stylesWith;
};

css.getAsStylesForHTML = function (prop) {
  let stylesWith = ``;
  this.getKeysWith(prop).forEach((selector) => {
    stylesWith += `<p>${selector} {</p> <p> &nbsp; &nbsp; ${this[selector].propAsStr(prop)}</p><p>}</p>`;
  });
  return stylesWith;
};

  
// получим все куски стилей со свойством ""
// console.log(css.getStylesWith('comments'));
  
  
  
//добавим метод возвращающий стили для селектора

css.getStyleOf = function (selector) {
  let styleOf = `${selector} {\n`;

  Object.keys(css[selector]).forEach(prop => {
    if (prop!='properties')
    styleOf += `  ${prop}: ${css[selector][prop]};\n`
  });

  styleOf += `}`
  return styleOf;
}

//sameasabove but htmlfriendly
css.getStyleOfForHTML = function (selector) {
  let styleOf = `<p>${selector} {</p>`;

  Object.keys(css[selector]).forEach(prop => {
    if (prop!='properties') {
    styleOf += `<p> &nbsp; &nbsp; ${prop}: ${css[selector][prop]}; </p>`;
    }
  });

  styleOf += `<p>}</p>`
  return styleOf;
}



//fullstyles. Html
css.getAsFullStylesForHTML = function (prop) {
  let res = ``;
  this.getKeysWith(prop).forEach(selector => {
    res += css.getStyleOfForHTML(selector);
  })
  return res;

}



let setOfSelectors = new Set();
let setOfProperties = new Set();

css.keys().forEach(select => {

  if (css[select].hasOwnProperty('properties')){
    setOfSelectors.add(select);

    Object.keys(css[select]).forEach(prop => {
      setOfProperties.add(prop);
    });
    
  }
  
});

setOfProperties.delete('comments');
setOfProperties.delete('properties'); 
css.setOfProperties = setOfProperties;
css.setOfSelectors = setOfSelectors;  

Object.defineProperties(css, {
  getAsFullStylesForHTML: { enumerable: false },
  getAsListForHTML: { enumerable: false },
  getAsStylesForHTML: { enumerable: false },
  getKeysWith: { enumerable: false },
  getStyleOf: { enumerable: false },
  getStyleOfForHTML: { enumerable: false },
  getStylesWith: { enumerable: false },
  keys: { enumerable: false },
  setOfProperties: { enumerable: false },
  setOfSelectors: { enumerable: false },
});

return css;
}