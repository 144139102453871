import { files } from "./app";

export let currentlyActive = new Set;
export function selectInputs (dropFiles, dropProperties) {
    
    const selectProp = document.querySelector('.select-property')
    
    const viewSelectors = document.querySelector('#view-selectors');
    const viewProperties = document.querySelector('#view-properties');

    const selectResult = document.querySelector('.select-result');
    
    
    const goBtn = document.querySelector('#go-btn');

    let currentName = undefined;
    
// Upon changing files inputs
    const changeDropProp = (e) => {

        
        const name = e.target.value;
        if (currentName === name) return;
        currentName = name;
        
        if (!(Object.keys(files).includes(currentName))) {
            const datalistBefore = document.querySelector('#properties-input');
            datalistBefore?.remove();
            selectProp.style.display = 'none';
            selectResult.style.display = 'none';
            dropProperties.value = '';
            
            makeInactive(viewProperties, viewSelectors, goBtn)

            document?.querySelector('.select-view')?.remove();

            return;
        };
        
        if (files[name].css.setOfProperties.size < 1) {
            alert('No properties detected. Please try another file')
            dropFiles.value = '';
            return;
        }


        selectProp.style.display = 'block';
        
        makeActive(viewProperties, viewSelectors)
        


        const datalistProp = element('datalist');
        datalistProp.id = "properties-input";
    
        dropProperties.insertAdjacentElement('afterend', datalistProp);
        
        files[name].css.setOfProperties.forEach(prop => {
            datalistProp.insertAdjacentHTML('beforeend', `
                <option value="${prop}"></option>
                `);
        });
        
    };



    
   

    const showSelectors = () => {
        if (!(currentlyActive.has(viewSelectors))) return;
        document?.querySelector('.select-view')?.remove();

        let selectorsAsText = element('div', 'select-view', 'previewFile');
        const name = dropFiles.value;

        let setOfSelectors = files[name].css.setOfSelectors;

        let header = element('h3');
        header.textContent = `${setOfSelectors.size} selectors:`;
        
        for (let selector of setOfSelectors) {
            
            selectorsAsText.insertAdjacentHTML('beforeend', `<p> ${selector} </p>`)
        }
        
        const selectViewArea = document.querySelectorAll('.half-select')[1];
        selectViewArea.insertAdjacentElement('afterbegin', selectorsAsText);
        selectorsAsText.insertAdjacentElement('afterbegin', header);
    };

    const showProperties = () => {
        if (!(currentlyActive.has(viewProperties))) return;
        document?.querySelector('.select-view')?.remove();

        let propertiesAsText = element('div', 'select-view', 'previewFile');
        const name = dropFiles.value;

        

        
        let setOfProperties = files[name].css.setOfProperties;
        let header = element('h3');
        header.textContent = `${setOfProperties.size} properties:`
        
        for (let property of setOfProperties) {
            
            propertiesAsText.insertAdjacentHTML('beforeend', `<p> ${property} </p>`)
        }
        
        const selectViewArea = document.querySelectorAll('.half-select')[1];
        selectViewArea.insertAdjacentElement('afterbegin', propertiesAsText);
        propertiesAsText.insertAdjacentElement('afterbegin', header);

    };

    
// upon changing properties
    const showRadio = (e) => {
        
        const name = dropFiles.value;
        const property = e.target.value;
        if (!(files[name].css.setOfProperties.has(property))) {
            selectResult.style.display = 'none';
            makeInactive (goBtn);
            return;
            
        }
        
        selectResult.style.display = 'block';
        makeActive (goBtn);
        
    };

    
    dropFiles.addEventListener('input', changeDropProp);   
    viewSelectors.addEventListener('click', showSelectors);
    viewProperties.addEventListener('click', showProperties);
    dropProperties.addEventListener('input', showRadio);


    

};

function element(tag, ...classes) {
    let element = document.createElement(tag);
    if (classes.length) {
        element.classList.add(...classes)
        
    }
    return element;
}

function makeActive(...buttons) {
    buttons.forEach(button => {
        button.classList.remove('gont');
        button.classList.add('go');

        currentlyActive.add(button);
    });
    
    
}

function makeInactive(...buttons) {
    buttons.forEach(button => {
        button.classList.remove('go');
        button.classList.add('gont');

        currentlyActive.delete(button)
    });
    
}