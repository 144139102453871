import { upload } from './upload.js';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { objFromCSS } from './objFromCSS.js';
import { selectInputs, currentlyActive } from './selectInputs'
export const files = {};


const firebaseConfig = {
    apiKey: "AIzaSyBXjRpw3kEi24HAgv-Z7a3SzedkeSpEqEU",
    authDomain: "cssfiles-3e082.firebaseapp.com",
    projectId: "cssfiles-3e082",
    storageBucket: "cssfiles-3e082.appspot.com",
    messagingSenderId: "990571882874",
    appId: "1:990571882874:web:ed4de5995378d3fc4a0ec3"
};
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);


const animate = (file, name, downloadURL) => {
    const ind = file.indicator.style;
    const open = file.open;
    const card = file.card;
    
    open.insertAdjacentHTML('afterbegin', `<a href=${downloadURL} target="blank">&#128065;</a>`);
    ind.background = 'rgba(153, 221, 174, 0.75)'
    const height = card.clientHeight;
    
    
    setTimeout(() => ind.transform = `translate(0px, -${height}px)`, 220);
    setTimeout(() => ind.transform = 'translate(0px, -0px)', 440);
    
    setTimeout(() => {
        ind.transform = 'translate(150px, 0px)';
        ind.width = '0px';
        
        if (files[name].isCSS !== false ) {
            card.style.background = ' #42928310 ';
        } else {
            card.style.background = '#ee777745'
            card.style.color = '#655';
            card.insertAdjacentHTML('beforeend', '<p class="notCSS"> Could not find properties</p>');

        }
    }, 660);
    setTimeout(() => ind.transform = 'translate(0px, 0px)', 880);

    setTimeout(() => {
        open.style.display = 'flex';
        
    }, 1100);
}
const onUpload = (previewFiles) => {
    previewFiles.forEach(file => {

        if (file.uploaded) return;
        
        const storageRef = ref(storage, 'CSSfiles/' + file.name);
        const metadata = {
            customMetadata: {
                'size':  `${file.size}`,
                'lastModified': `${file.lastModified}`,
                'info':  `${file.name}_${file.size}_${file.lastModified}`,
            }
        };
    
        const uploadTask = uploadBytesResumable(storageRef, file.blob, metadata);
        uploadTask.on('state_changed',

        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            
            file.indicator.style.width = progress*0.9 + '%';
            console.log('Upload is ' + progress + '% done');
            // switch (snapshot.state) {
            // case 'paused':
            //     console.log('Upload is paused');
            //     break;
            // case 'running':
            //     console.log('Upload is running');
            //     break;
            // }
        }, 

        (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
            case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
            case 'storage/canceled':
                // User canceled the upload
                break;

            // ...

            case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
        }, 

        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            

                file.uploaded = true;
                const name = file.name;

                //creating obj
                files[name] = {
                    css: objFromCSS(file.text),
                    isCSS: true,
                    download: downloadURL,
                };
                
                //Checking if it is alike css file. 6 is default inner methods
                if (Object.keys(files[name].css).length < 1) {
                    files[name].isCSS = false;
                };

                //animating uploading
                
                animate(file, name, downloadURL)

                
                //adding drop-options for files (filenames)
                
                const datalistFiles = document.querySelector('#files-input');
                
                datalistFiles.insertAdjacentHTML('beforeend', `
                    <option value="${name}"></option>
                `);

            });

        }


        );

    });

}

const params = {
    multi: true,
    accept: ['.css', '.txt'],
    onUpload,
    
}
upload('#file', params)

const dropFiles = document.querySelector('#drop-files');
const dropProperties = document.querySelector('#drop-prop');
const goBtn = document.querySelector('#go-btn');

selectInputs(dropFiles, dropProperties);



const go = () => {
    if (!currentlyActive.has(goBtn)) return;
    document.querySelector('.result-dinam')?.remove();
    
    const name = dropFiles.value;
    const css = files[name].css;

    const prop = dropProperties.value;
    
    const resultArea = document.querySelector('.result-area');


    //в зависимости от рэдио мы запускаем методы
  
    const radio = document.querySelector('input[name="result-choice"]:checked').value;

    const result = document.createElement('div');
    result.classList.add('result-dinam');
    

    switch (radio) {
        
        case 'asList':
            
            result.insertAdjacentHTML('afterbegin', css.getAsListForHTML(prop));
            
            break;
      
        case 'asStyles':
                        
            result.insertAdjacentHTML('afterbegin', css.getAsStylesForHTML(prop));
            
            break;
      
        case 'asFullStyles':
            
            result.insertAdjacentHTML('afterbegin', css.getAsFullStylesForHTML(prop));
            
            break;

            default:
                result.insertAdjacentHTML('afterbegin', `
                <p> something went wrong </p>
            `);
            console.log( 'smth is wrong. radio: ', radio );
      };
      resultArea.insertAdjacentElement('afterbegin', result)
}






goBtn.addEventListener('click', go);


function log (...smth) {

    smth.forEach(unit => console.log(unit));
}


